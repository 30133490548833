.text-notification-container {
  min-height: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: var(--clamp-message-after-lines);
  -webkit-box-orient: vertical;
  opacity: 0.8;
}

.mark-as-button:hover > div {
  text-decoration: underline !important;
}

.text-notification-container-dark a {
  color: white;
  font-style: italic;
  opacity: 1 !important;
}

.notification-card-container {
  transition: all 0.2s ease-in-out;
  border-radius: 1px;
}
