.recipient {
  border-radius: 8px;
  width: fit-content;
  overflow: hidden;
  background-color: white;
  height: 52px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.recipient :hover {
  background-color: #f5f5f5 !important;
}
