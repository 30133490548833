.loading-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dot {
  border-radius: 50%;
  animation: bounce 1.5s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.15s;
}

.dot:nth-child(3) {
  animation-delay: 0.25s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(calc(-1 * var(--dot-jump, 65%)));
  }
}
